import React, { useState } from "react";
import { Card } from "react-bootstrap";
import "./CaregiverPlan.css";
import { IoIosArrowForward } from "react-icons/io";
import BookingModal from "../Modal/BookingModal";

const CaregiverPlan = ({ plan }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState("");
  const [subtitle, setSubtitle] = useState("");

  if (!plan || !plan.childes || plan.childes.length === 0) {
    return null;
  }

  const caregiverPlanData = plan.childes;

  const handleBookNowClick = (price, subTitle) => {
    setSelectedPrice(price);
    setSubtitle(subTitle);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div className="caregiver-plan-wrapper CaregiverPlanmain">
      <div className="row g-4 justify-content-center">
        <div className="col-lg-3 col-12 text-start">
          <h4>{plan?.title}</h4>
        </div>
        {caregiverPlanData.map((element, index) => (
          <div className="col-lg-4 col-12 text-start" key={index}>
            <Card
              className="card"
              onClick={() =>
                handleBookNowClick(element.subsubtitle, element.subtitle)
              }
            >
              <h2 className="hover-effect">
                {element?.subtitle}
                <IoIosArrowForward />
              </h2>
              <p>{element?.description}</p>
            </Card>
          </div>
        ))}
      </div>
      <BookingModal
        show={showModal}
        handleClose={handleModalClose}
        price={selectedPrice}
        subtitle={subtitle}
      />
    </div>
  );
};

export default CaregiverPlan;
